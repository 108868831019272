
		export default {
			staticResourcesBucketUrl: 'https://s3-eu-central-1.amazonaws.com/sturcz-staticresourcess3bucket-twry03b7j8oy',
			documentStoreBucketUrl: 'https://s3-eu-central-1.amazonaws.com/sturcz-documentstores3bucket-1u3y5imur8inv',
			apiGatewayBaseUrl: 'https://f1eybz8od2.execute-api.eu-central-1.amazonaws.com/Prod',
			realtimeTrackingWebSocketUri: 'wss://wlup5dbmb9.execute-api.eu-central-1.amazonaws.com/Prod',
			languageCodes: 'hu,en'.split(','),
			onesignalAuthToken: 'Basic NjExYTMzNTAtNjdiZC00MTI1LTk2NzQtODcxZTA3N2YyNWYz',
			onesignalAppId: 'b5cb7836-507c-4183-951e-9af11d841b8f',
			statTypes: '{"data":["heatmap","real-time","active-users-monthly","active-users-daily","total-users","total-zone-visits-monthly","total-zone-visits","dwell-time-by-dow","asked-per-answered-quiz","right-per-wrong-quiz","rating-value","push-sent","push-opened","treasure_hunt-gamers","asked-per-answered-treasure_hunt"]}'
		}
	
